import ReactDOM from 'react-dom';
import { isMobile } from 'react-device-detect';
// import { ClientApp } from './App';
// import { Reload } from './components';
// import { register } from './serviceWorker.js';
import LazyApp from 'LazyApp';
import Thea from 'modules/TheaIOSPage';
import 'assets/styles/_avatusDefault.scss';
import { createRoot } from 'react-dom/client';

const locationInfo = window?.location ? window.location : null;

const container = document.getElementById('root'); 
if (container) {
    const root = createRoot(container); // createRoot(container!) if you use TypeScript
    root.render(isMobile && locationInfo?.pathname === '/thea/ios/view' ? <Thea /> : <LazyApp />)
}
// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// register({
//   onUpdate: () => {
//     store.dispatch(showAlert(<Reload />, { id: 'sw-update', icon: 'bolt', timeout: 0 }));
//   },
// });
