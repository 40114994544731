import React, { useEffect, useState } from 'react';
import LayoutWrapperMain from 'components/LayoutWrapperMain';
import CircularProgressLoader from 'components/Loaders/CircularProgress';
import Toast from 'components/Toast';
import Cookies from 'js-cookie';
import scss from '../TheaPage/TheaPage.module.scss';

declare global {
  interface Window {
    Tilia?: any;
  }
}

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const apiKey = process.env.REACT_APP_X_API_KEY;

const Thea = () => {
  const [url, seturl] = useState('');
  const [loading, setLoading] = useState(true);
  const [toastState, setToastState] = useState({
    show: false,
    message: '',
  });

  const location = window?.location ? window.location : null;
  const authorizedToken = Cookies.get('jwt_access_token');
  const currentTheme = Cookies.get('st-theme');
  const token = (location?.search ? new URLSearchParams(location.search)?.get('token') : undefined) || authorizedToken;
  const theme = (location?.search ? new URLSearchParams(location.search)?.get('theme') : undefined) || currentTheme;

  useEffect(() => {
    const fetchUrl = async (t: string, key: string) => {
      const tokenMod = !t.includes('Bearer') ? `Bearer ${t}` : t;
      try {
        const response = await fetch(`${BASE_URL}/api/v1/users/encrypt`, {
          headers: {
            accept: 'application/json, text/plain, */*',
            'accept-language': 'en-US,en;q=0.9',
            authorization: tokenMod,
            language: 'en',
            platform: '3',
            'x-api-key': key,
          },
          body: null,
          method: 'GET',
          mode: 'cors',
          credentials: 'same-origin',
        });
        const json = await response.json();
        const encrypted = json?.data || false;
        setLoading(false);
        if (encrypted) {
          seturl(`https://thea.avatus.com/?token=${encrypted.replace(/\+/g, '%2B')}&theme=${theme === 'light' ? 'light' : 'dark'}`);
        } else {
          seturl('');
          throw (new Error('Something went wrong.'));
        }
      } catch {
        setLoading(false);
        setToastState({
          show: true,
          message: 'Something went wrong. Please try again later.',
        });
      }
    };
    if (token && apiKey) {
      fetchUrl(token, apiKey);
    }
  }, [token, theme]);

  if (!token) {
    return <div>User not authorized</div>;
  }

  return (
    <div className={scss.mobileColumn}>
      <LayoutWrapperMain className="mobile_padding">
        <div className="custom_container">
          {loading ? (
            <CircularProgressLoader />
          ) : url ? (
            <iframe src={url} className={scss.theaContainer} title="Thea" />
          ) : null}
          <Toast
            open={toastState.show}
            message={toastState?.message}
            handleClose={() => {
              setToastState({ ...toastState, show: false });
              return null;
            }}
          />
        </div>
      </LayoutWrapperMain>
    </div>
  );
};

export default Thea;
