import React from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));

interface Props {
  className?: string
}

export default function Loading(props: Props) {
  const { className } = props;
  const classes = useStyles();
  return (
    <div className={`${classes.root} ${className}`}>
      <CircularProgress role="progressbar" id="progress" title="Progress" aria-label="progress" aria-labelledby="progress" />
    </div>
  );
}

